<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-28 16:12:11
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-14 22:45:06
-->
<template>
  <div class="flex-y center">
    <div class="img-box qrcode">
      <img :src="info.qrCode" alt="" />
    </div>
    <div class="btn img-box">
      长按保存图片
      <img :src="info.qrCode" alt="" hidden />
    </div>
    <div class="info">
      <div class="info__title">对公收款账号</div>
      <div class="info__row">开户名称：{{ info.accountName }}</div>
      <div class="info__row">开户行：{{ info.accountBank }}</div>
      <div class="info__row">对公账号：{{ info.account }}</div>
    </div>
  </div>
</template>

<script>
import { getPayInfo } from "../../../utils/api/utils";
export default {
  data() {
    return {
      info: {},
    };
  },
  created() {
    getPayInfo().then((res) => {
      this.info = res.data.data;
    });
  },
};
</script>

<style src="../../../assets/css/user/pay.css" scoped>
</style>